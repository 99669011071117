import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuContentComponent } from './menu-content/menu-content.component';
import { MenuTriggerComponent } from './menu-trigger/menu-trigger.component';
import { MenuComponent } from './menu/menu.component';
import { MenuItemComponent } from './menu-item/menu-item.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MenuComponent,
    MenuTriggerComponent,
    MenuContentComponent,
    MenuItemComponent,
  ],
  exports: [
    MenuComponent,
    MenuTriggerComponent,
    MenuContentComponent,
    MenuItemComponent,
  ],
})
export class MenuModule {}
