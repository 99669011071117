<div class="drawer-header">
  <div class="drawer-header-close">
    <div class="btn btn-icon btn-small" (click)="close()">
      <div class="icon icon-close"></div>
    </div>
  </div>
  <div class="drawer-header-actions">
    <ng-content select=".btn"></ng-content>
    <!-- <div class="btn btn-icon">
      <div class="icon icon-link"></div>
    </div>
    <div class="btn btn-icon">
      <div class="icon icon-copy"></div>
    </div>
    <div class="btn btn-icon">
      <div class="icon icon-delete"></div>
    </div> -->
  </div>
</div>
<div class="drawer-title" *ngIf="title">{{ title }}</div>
