import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-drawer-header',
  templateUrl: './drawer-header.component.html',
  styleUrl: './drawer-header.component.scss',
})
export class DrawerHeaderComponent {
  @Input() title?: string;
  constructor(private _dialogRef: DialogRef) {}

  close() {
    this._dialogRef.close(null);
  }
}
