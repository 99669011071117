import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { overlayPositions } from '@app/core/data/overlay-position';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  @Input() close = true;
  overlayPosition = overlayPositions;
  open = false;

  onClose() {
    this.open = false;
  }

  onOpen() {
    this.open = true;
  }
}
