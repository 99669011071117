import { FormControl, FormGroup } from '@angular/forms';
import { IProject } from '@app/models/project';

export const projectForm = (project?: IProject) => {
  const form = new FormGroup({
    name: new FormControl(project?.name || ''),
    description: new FormControl(project?.description || ''),
    platforms: new FormControl<number[]>([]),
    photo: new FormControl(project?.photo ?? null),
  });

  if (project) {
    form.controls.platforms.setValue(project.platforms.map((p) => p._id));
  }

  return form;
};
