import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { projectForm } from '@app/forms/project.form';
import { IProject } from '@app/models/project';
import { ProjectsService } from '@app/services/projects.service';
import { DrawerModule } from '@app/shared/components/drawer/drawer.module';
import { FormControlsModule } from '@app/shared/form-controls/form-controls.module';
import { SharedModule } from '@app/shared/shared.module';

@Component({
  selector: 'app-project-create',
  standalone: true,
  imports: [CommonModule, DrawerModule, FormControlsModule, SharedModule],
  templateUrl: './project-create.component.html',
  styleUrl: './project-create.component.scss',
})
export class ProjectCreateComponent {
  public form = projectForm();

  platforms = [
    { _id: 1, name: 'IOS' },
    { _id: 2, name: 'Android' },
    { _id: 3, name: 'Web' },
  ];

  constructor(
    private _dialogRef: DialogRef,
    private _projectService: ProjectsService
  ) {}

  onPlatformChange(toggle: boolean, platformId: number) {
    const platforms = this.form.value.platforms ?? [];
    if (toggle && !platforms.includes(platformId)) {
      platforms.push(platformId);
    }
    if (!toggle && platforms.includes(platformId)) {
      platforms.splice(platforms.indexOf(platformId), 1);
    }
    this.form.controls.platforms.setValue(platforms);
  }

  onCreate() {
    this._projectService
      .create(this.form.value as unknown as IProject)
      .subscribe((project) => {
        this._dialogRef.close(project);
      });
  }
}
