import { Component } from '@angular/core';

@Component({
  selector: 'app-drawer-footer',
  templateUrl: './drawer-footer.component.html',
  styleUrl: './drawer-footer.component.scss'
})
export class DrawerFooterComponent {

}
