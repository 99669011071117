import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-drawer-actions',
  templateUrl: './drawer-actions.component.html',
  styleUrl: './drawer-actions.component.scss',
})
export class DrawerActionsComponent {
  @Output() create: EventEmitter<void> = new EventEmitter();
  @Output() update: EventEmitter<void> = new EventEmitter();
  @Input() entityId!: number;

  public get isWindows() {
    return window.navigator.userAgent.includes('Windows');
  }
  public get isMac() {
    return window.navigator.userAgent.includes('Macintosh');
  }

  @HostListener('document:keyup', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    const { ctrlKey, key } = event;
    if (this.entityId && ctrlKey && key === 'Enter') {
      this.update.emit();
    }
  }
}
