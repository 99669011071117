import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer/drawer.component';
import { DrawerHeaderComponent } from './drawer-header/drawer-header.component';
import { DrawerFooterComponent } from './drawer-footer/drawer-footer.component';
import { DrawerContentComponent } from './drawer-content/drawer-content.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DrawerActionsComponent } from './drawer-actions/drawer-actions.component';

@NgModule({
  declarations: [
    DrawerComponent,
    DrawerHeaderComponent,
    DrawerFooterComponent,
    DrawerContentComponent,
    DrawerActionsComponent,
  ],
  exports: [
    DrawerComponent,
    DrawerHeaderComponent,
    DrawerFooterComponent,
    DrawerContentComponent,
    DrawerActionsComponent,
  ],
  imports: [CommonModule, NgScrollbarModule],
})
export class DrawerModule {}
