import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-menu-trigger',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-trigger.component.html',
  styleUrl: './menu-trigger.component.scss',
})
export class MenuTriggerComponent {
  @Input() template!: TemplateRef<unknown>;
}
