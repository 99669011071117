import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@app/core/models/api';
import { ApiService } from '@app/core/services/api.service';
import { DialogService } from '@app/core/services/dialog.service';
import { ProjectCreateComponent } from '@app/dialogs/project-create/project-create.component';
import { IProject, IProjectFind, IProjectMember } from '@app/models/project';
import { ReplaySubject, map } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService extends ApiService<IProject, IProjectFind> {
  private _api = `${environment.ApiV1}/projects`;

  private _projects$: ReplaySubject<IProject[]> = new ReplaySubject();
  private _projects: IProject[] = [];
  private _selectedProject$: ReplaySubject<IProject> = new ReplaySubject();
  private _selectedProject!: IProject;

  public get projects() {
    return this._projects;
  }

  public get selectedProject() {
    return this._selectedProject;
  }

  public get api(): string {
    return this._api;
  }

  public get projects$() {
    return this._projects$.asObservable();
  }

  public get selectedProject$() {
    return this._selectedProject$.asObservable();
  }

  constructor(
    private _http: HttpClient,
    private _dialogService: DialogService
  ) {
    super(_http);

    this.getUserProjects();
  }

  public getUserProjects() {
    this.find({ limit: 100 }).subscribe((res) => {
      this._projects$.next(res.docs);
      this._projects = res.docs;
    });
  }

  public changeProject(projectId: number) {
    this._projects$
      .pipe(map((projects) => projects.filter((p) => p._id == projectId)))
      .subscribe((result) => {
        if (result.length) {
          this._selectedProject = result[0];
          this._selectedProject$.next(result[0]);
        }
      });
  }

  public inviteUser(project: number, payload: IProjectMember) {
    return this._http.post(`${this._api}/${project}/team`, payload);
  }

  public loadSharedProjects() {
    return this._http.get<Api<IProject>>(`${this._api}/shared`);
  }

  public updateTeamMember(
    project: number,
    memberId: number,
    payload: IProjectMember
  ) {
    return this._http.patch(
      `${this._api}/${project}/team/${memberId}`,
      payload
    );
  }

  public deleteTeamMember(project: number, memberId: number) {
    return this._http.delete(`${this._api}/${project}/team/${memberId}`);
  }

  public getTeam(project: number) {
    return this._http.get<Api<IProjectMember>>(`${this._api}/${project}/team`);
  }

  onCreate() {
    return this._dialogService.drawer<IProject>(ProjectCreateComponent);
  }
}
