<div class="menu">
  <div
    class="menu-trigger"
    #trigger
    cdk-overlay-origin
    #overlayOrigin="cdkOverlayOrigin"
    (click)="onOpen()">
    <ng-content select="app-menu-trigger"></ng-content>
  </div>
</div>

<ng-template
  #menuListTmpl
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayPositions]="overlayPosition"
  (backdropClick)="onClose()">
  <div class="menu-container">
    <ng-content select="app-menu-content"></ng-content>
  </div>
</ng-template>
