@if (headerTmpl) {
<div class="menu-header">
  <ng-container [ngTemplateOutlet]="headerTmpl"></ng-container>
</div>
}
<div class="menu-items">
  <ng-content select="app-menu-item"></ng-content>
</div>
@if (footerTmpl) {
<div class="menu-footer">
  <ng-container [ngTemplateOutlet]="footerTmpl"></ng-container>
</div>
}
