<div class="create-button" *ngIf="!entityId">
  <button
    class="btn btn-brand-400 btn-primary btn-small"
    (click)="create.emit()">
    Create
  </button>
</div>

<div class="update-button" *ngIf="entityId">
  <button
    class="btn btn-brand-400 btn-primary btn-small"
    (click)="update.emit()">
    Save changes
  </button>
  <div class="update-button-text" *ngIf="isMac || isWindows">
    or press
    <div class="update-button-text-button">{{ isMac ? "CMD" : "CTRL" }}</div>
    +
    <div class="update-button-text-button">Enter</div>
  </div>
</div>
