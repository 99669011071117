<div class="menu-item-wrapper">
  @if (template) {
  <ng-container [ngTemplateOutlet]="template"></ng-container>
  } @else {
  <div class="menu-item-default">
    <a [routerLink]="link" class="menu-item">
      <div class="menu-item-icon">
        <ng-content select="[menuItemIcon]"></ng-content>
      </div>
      <div class="menu-item-text"><ng-content></ng-content></div>
    </a>
  </div>
  }
</div>
