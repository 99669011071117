<app-drawer>
  <app-drawer-header></app-drawer-header>
  <app-drawer-content>
    <div class="section" [formGroup]="form">
      <div class="text text-icon text-brand-400">
        <div class="icon icon-brand-400 icon-project"></div>
        Project
      </div>

      <div class="input-group m-b-8">
        <app-input
          formControlName="name"
          [ghost]="true"
          size="large"
          placeholder="enter project name"></app-input>
      </div>

      <input
        type="text"
        placeholder="add description"
        formControlName="description"
        class="input-ghost" />
    </div>
    <app-toggle
      *ngFor="let platform of platforms"
      (toggle)="onPlatformChange($event, platform._id)"
      >{{ platform.name }}</app-toggle
    >
  </app-drawer-content>
  <app-drawer-footer>
    <div class="btn-group">
      <div class="btn btn-brand-400 btn-primary btn-small" (click)="onCreate()">
        Save changes
      </div>
    </div>
  </app-drawer-footer>
</app-drawer>
