import { Component } from '@angular/core';

@Component({
  selector: 'app-drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrl: './drawer-content.component.scss'
})
export class DrawerContentComponent {

}
