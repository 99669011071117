import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, TemplateRef } from '@angular/core';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-menu-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './menu-content.component.html',
  styleUrl: './menu-content.component.scss',
})
export class MenuContentComponent {
  @Input() headerTmpl!: TemplateRef<unknown>;
  @Input() footerTmpl!: TemplateRef<unknown>;

  constructor(private _menu: MenuComponent) {}

  @HostListener('click')
  onCLick() {
    if (this._menu.close) {
      this._menu.onClose();
    }
  }
}
